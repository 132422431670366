import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';



import { GoogleMapScript } from './components/googleMapScript';
import HomePage from './views/homePage';
import SettingsPage from './views/settingsPage';
import LoginPage from './views/loginPage';
import ListPage from './views/listPage';
import FormPage from './views/formPage';
import EbayPage from './views/ebayPage';
import EbayCreateOffer from './views/ebayCreateOffer';
import Order from './views/order';
//const history = createHistory();
import SkinConsultBuilder from './views/skinConsultBuilder';
import Statistics from './views/statistics';


let formPages = [
    {
        title: 'Administratori',
        subtitle: '',
        link: '/admins',
        collection: 'admins',
        multilang: false,
        fields: [
            {
                name: 'username',
                label: 'Username',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'pk',
                label: 'Lozinka',
                type: 'password',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },

            {
                name: 'userRole',
                label: 'Tip admina',
                type: 'select',
                values: [
                    {
                        name: 'SUPERIOR',
                        value: 'super'
                    }, {
                        name: 'SIMPLE',
                        value: 'simple'
                    }, {
                        name: 'SPECIAL',
                        value: 'special'
                    },

                ],
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },

        ],
        linkedFields: [

        ]
    },
    {
        title: 'Brendovi',
        subtitle: '',
        link: '/brands',
        collection: 'brands',
        multilang: false,
        fields: [
            {
                name: 'Name',
                label: 'Ime',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Image',
                label: 'Slika',
                type: 'file',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: 'Position',
                label: 'Pozicija',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
        ],
        linkedFields: [

        ]
    },
    {
        title: 'Firme',
        subtitle: '',
        link: '/companies',
        collection: 'companies',
        multilang: false,
        fields: [
            {
                name: 'Link',
                label: 'Link',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Image',
                label: 'Slika',
                type: 'file',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: 'Position',
                label: 'Pozicija',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
        ],
        linkedFields: [

        ]
    },

    {
        title: 'O nama',
        subtitle: '',
        link: '/aboutus',
        collection: 'aboutus',
        multilang: false,
        fields: [
            {
                name: 'companyData',
                label: 'Podaci o firmi',
                type: 'html',
                multilang: false,

                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Content',
                label: 'Tekst',
                type: 'html',
                multilang: false,

                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
        ],
        linkedFields: [

        ]
    },
    {
        title: 'Newsletter',
        subtitle: 'Configure newsletter',
        link: '/newsletters',
        collection: 'newsletters',
        multilang: false,
        fields: [
            {
                name: 'Title',
                label: 'Title',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            }, {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Image',
                label: 'Image',
                type: 'file',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            }, {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Text',
                label: 'Text',
                type: 'html',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                type: 'list',
                name: 'products',
                width: {
                    desktop: 4,
                    mobile: 12
                },

                fields: [
                    {
                        type: 'image',
                        name: 'Image',
                        width: {
                            desktop: 2,
                            mobile: 2
                        }
                    },
                    {
                        type: 'text',
                        name: 'Alias',
                        width: {
                            desktop: 4,
                            mobile: 4
                        }
                    },
                    {
                        type: 'text',
                        name: 'Name.de',
                        width: {
                            desktop: 4,
                            mobile: 4
                        }
                    },
                ],

            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                id: '342yh43hi234',
                type: 'search',
                label: 'Search products',
                collection: 'products',
                searchFields: ['Alias', 'Name.de'],
                fields: [
                    {
                        type: 'image',
                        name: 'Image'
                    },
                    {
                        type: 'text',
                        name: 'Alias'
                    },
                    {
                        type: 'text',
                        name: 'Name.de'
                    }
                ],
                limit: 10,
                onSelect: (scope, value) => {
                    console.log(value);
                    let fields = scope.state.fields;
                    let initialValues = scope.state.initialValues;
                    console.log(initialValues['products']);
                    if (!initialValues['products'])
                        initialValues['products'] = [];
                    initialValues['products'].push(value);
                    for (let i = 0; i < fields.length; i++) {
                        fields[i]._temp = new Date();

                    }
                    scope.setState({ initialValues: initialValues, fields: [] }, () => {
                        scope.forceUpdate();
                        scope.setState({ fields: fields });
                    });


                },
                width: {
                    desktop: 4,
                    mobile: 12
                }
            }, {
                type: 'spacer'
            },
        ],
        linkedFields: [

        ]
    },
    {
        title: 'Narudžba',
        subtitle: 'Pregled narudžbe',
        link: '/orders',
        collection: 'orders',
        multilang: false,
        fields: [
            {
                type: 'title',
                text: 'Podešavanje fakture'
            },
            {
                name: 'FiscalNumber',
                label: 'Broj fiskalnog računa',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'Charged',
                label: 'Datum fakturisanja',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'Delivered',
                label: 'Datum isporuke',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            /*
            {
                name: 'BillMaxDateToPay',
                label: 'Valuta plaćanja',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            }, /*{
                name: 'Warehouse',
                label: 'Skladište',
                type: 'select',
                values: [
                    {
                        name: '1 - MAGACIN',
                        value: '1 - MAGACIN'
                    }, {
                        name: '2 - VELEPRODAJA',
                        value: '2 - VELEPRODAJA'
                    }, {
                        name: '3 - MALOPRODAJA',
                        value: '3 - MALOPRODAJA'
                    },

                ],
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },*/
            {
                type: 'spacer'
            },

            // {
            //     type: 'title',
            //     text: 'Adresa za obračun'
            // },

            // {
            //     name: 'BillingAddress.FirstName',
            //     label: 'Ime',
            //     type: 'text',
            //     multilang: false,
            //     width: {
            //         desktop: 6,
            //         mobile: 12
            //     }
            // },
            // {
            //     name: 'BillingAddress.LastName',
            //     label: 'Prezime',
            //     type: 'text',
            //     multilang: false,
            //     width: {
            //         desktop: 6,
            //         mobile: 12
            //     }
            // },
            // {
            //     name: 'BillingAddress.Street',
            //     label: 'Ulica',
            //     type: 'text',
            //     multilang: false,
            //     width: {
            //         desktop: 6,
            //         mobile: 12
            //     }
            // },
            // {
            //     name: 'BillingAddress.City',
            //     label: 'Grad',
            //     type: 'text',
            //     multilang: false,
            //     width: {
            //         desktop: 5,
            //         mobile: 12
            //     }
            // },
            // {
            //     name: 'BillingAddress.Zipcode',
            //     label: 'Poštanski broj',
            //     type: 'text',
            //     multilang: false,
            //     width: {
            //         desktop: 1,
            //         mobile: 12
            //     }
            // },
            // {
            //     name: 'BillingAddress.Company',
            //     label: 'Firma',
            //     type: 'text',
            //     multilang: false,
            //     width: {
            //         desktop: 6,
            //         mobile: 12
            //     }
            // },
            // {
            //     name: 'BillingAddress.EMail',
            //     label: 'EMail',
            //     type: 'text',
            //     multilang: false,
            //     width: {
            //         desktop: 3,
            //         mobile: 12
            //     }
            // },
            // {
            //     name: 'BillingAddress.Phone',
            //     label: 'Telefon',
            //     type: 'text',
            //     multilang: false,
            //     width: {
            //         desktop: 3,
            //         mobile: 12
            //     }
            // },
            // {
            //     name: 'BillingAddress.JIB',
            //     label: 'JIB',
            //     type: 'text',
            //     multilang: false,
            //     width: {
            //         desktop: 3,
            //         mobile: 12
            //     }
            // },
            // {
            //     name: 'BillingAddress.PDV',
            //     label: 'PDV',
            //     type: 'text',
            //     multilang: false,
            //     width: {
            //         desktop: 3,
            //         mobile: 12
            //     }
            // },
            // {
            //     name: 'BillingAddress.MB',
            //     label: 'MB',
            //     type: 'text',
            //     multilang: false,
            //     width: {
            //         desktop: 3,
            //         mobile: 12
            //     }
            // },
            // {
            //     name: 'BillingAddress.TaxPayer',
            //     label: 'Obavezik PDV-a',
            //     type: 'select',
            //     values: [
            //         {
            //             name: 'NE',
            //             value: '0'
            //         }, {
            //             name: 'DA',
            //             value: '1'
            //         }
            //     ],
            //     multilang: false,
            //     width: {
            //         desktop: 3,
            //         mobile: 12
            //     }
            // },


            {
                type: 'title',
                text: 'Adresa za isporuku'
            },

            {
                name: 'ShippingAddress.Name',
                label: 'Ime i prezime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Street',
                label: 'Ulica',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'ShippingAddress.City',
                label: 'Grad',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Zipcode',
                label: 'Postanski broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Country',
                label: 'Drzava',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Region',
                label: 'Regija',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },

            {
                name: 'ShippingAddress.EMail',
                label: 'EMail',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Phone',
                label: 'Telefon',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },

        ],
        linkedFields: [

        ]
    },
    {
        title: 'Gratis proizvodi',
        subtitle: '',
        link: '/gratis-products',
        collection: 'gratisProducts',
        multilang: false,
        fields: [
            {
                name: 'limits',
                label: 'Limiti',
                type: 'limits',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },



        ],
        linkedFields: []
    },
    {
        title: 'Besplatna dostava',
        subtitle: '',
        link: '/free-delivery',
        collection: 'freeDeliveryProducts',
        multilang: true,
        fields: [
            {
                name: 'message',
                label: 'Poruka (zelena traka)',
                type: 'text',
                multilang: true,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'link',
                label: 'Link (zelena traka)',
                type: 'text',
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'products',
                label: 'Proizvodi',
                type: 'listOfProducts',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'message2',
                label: 'Poruka (druga zelena traka)',
                type: 'text',
                multilang: true,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'link2',
                label: 'Link (druga zelena traka)',
                type: 'text',
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },



        ],
        linkedFields: []
    },
    {
        title: 'Proizvod',
        subtitle: 'Dodaj/Izmjeni proizvod',
        link: '/products',
        collection: 'products',
        multilang: true,
        fields: [
            {
                name: 'Alias',
                label: 'Šifra proizvoda',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BarCode',
                label: 'Bar kod',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                "type": "offset",
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'IsVisible',
                label: 'Proizvod je vidljiv u shopu',
                type: 'checkbox',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'IsPromoted',
                label: 'Izdvojen proizvod',
                type: 'checkbox',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'IsAvailableForFree',
                label: 'Gratis proizvod',
                type: 'checkbox',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'NewProduct',
                label: 'Novi proizvod',
                type: 'checkbox',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'IsOnOffer',
                label: 'Na akciji',
                type: 'checkbox',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },

            {
                name: 'ShowDiscountPrice',
                label: 'Prikazi prekrizenu cijenu',
                type: 'checkbox',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },


            /*{
                name: 'ProductForLegalPersons',
                label: 'Velprodajni prozivod',
                type: 'checkbox',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },*/
            {
                "type": "spacer"
            },
            {
                name: 'Images',
                label: 'Ostale slike',
                type: 'file-multiple-multilang',
                width: {
                    desktop: 9,
                    mobile: 12
                }
            },
            {
                name: 'Comments',
                label: 'Komentari',
                type: 'file-multiple-multilang',
                width: {
                    desktop: 9,
                    mobile: 12
                }
            },
            {
                "type": "offset",
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Name',
                label: 'Naziv proizvoda',
                type: 'text',
                multilang: true,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer',
            },
            {
                name: 'YouTubeId',
                label: 'YouTubeId',
                type: 'text',
                multilang: true,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'spacer',
            },

            {
                name: 'package[0].name',
                label: 'Pakovanje #1',
                multilang: true,
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 6
                }
            },
            {
                name: 'package[0].unitPrice.de',
                label: 'MPC #1 - DE',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },
            {
                name: 'package[0].unitPrice.at',
                label: 'MPC #1 - AT',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },
            {
                name: 'package[0].discount',
                label: '% stare cijene',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },
            {
                name: 'package[0].sku',
                label: 'SKU',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },


            {
                "type": "offset",
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'package[1].name',
                label: 'Pakovanje #2',
                multilang: true,
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 6
                }
            },
            {
                name: 'package[1].unitPrice.de',
                label: 'MPC #2 - DE',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },
            {
                name: 'package[1].unitPrice.at',
                label: 'MPC #2 - AT',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },
            {
                name: 'package[1].discount',
                label: '% stare cijene',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },
            {
                name: 'package[1].sku',
                label: 'SKU',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },

            {
                "type": "offset",
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'package[2].name',
                label: 'Pakovanje #3',
                multilang: true,
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 6
                }
            },
            {
                name: 'package[2].unitPrice.de',
                label: 'MPC #3 - DE',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },
            {
                name: 'package[2].unitPrice.at',
                label: 'MPC #3 - AT',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },
            {
                name: 'package[2].discount',
                label: '% stare cijene',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },
            {
                name: 'package[2].sku',
                label: 'SKU',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },


            {
                "type": "offset",
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'package[3].name',
                label: 'Pakovanje #4',
                multilang: true,
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 6
                }
            },
            {
                name: 'package[3].unitPrice.de',
                label: 'MPC #4 - DE',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },
            {
                name: 'package[3].unitPrice.at',
                label: 'MPC #4 - AT',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },
            {
                name: 'package[3].discount',
                label: '% stare cijene',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },

            {
                name: 'package[3].sku',
                label: 'SKU',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },

            {
                "type": "offset",
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'package[4].name',
                label: 'Pakovanje #5',
                multilang: true,
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 6
                }
            },
            {
                name: 'package[4].unitPrice.de',
                label: 'MPC #5 - DE',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },
            {
                name: 'package[4].unitPrice.at',
                label: 'MPC #5 - AT',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },
            {
                name: 'package[4].discount',
                label: '% stare cijene',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },

            {
                name: 'package[4].sku',
                label: 'SKU',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },

            {
                "type": "offset",
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                type: 'spacer',
            },

            {
                name: 'MinOrder',
                label: 'Minimalna narudzba (komada)',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'StockLevel',
                label: 'Na stanju',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'StockLevelAlert',
                label: 'Upozorenje za magacin (komada)',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                type: 'spacer',
            },
            {
                name: 'Volume',
                label: 'Kubikaža',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },

            {
                name: 'Weight',
                label: 'Težina',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'QuantityInSet',
                label: 'Komada u setu',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },

            {
                type: 'spacer',
            },
            {
                name: 'ShortDescription',
                label: 'Kratak opis artikla',
                type: 'html',
                multilang: true,

                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                name: 'Composition',
                label: 'Sastav artikla',
                type: 'html',
                multilang: true,

                width: {
                    desktop: 12,
                    mobile: 12
                }

            },

            {
                name: 'Description',
                label: 'Opis artikla',
                type: 'html',
                multilang: true,

                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'spacer',
            },
            {
                type: 'label',
                beforeText: 'Sekcija #1',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'section[0].Image',
                label: 'Pozadinska slika',
                type: 'file',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[0].MobileImage',
                label: 'Mobilna Pozadinska slika',
                type: 'file',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[0].TabletImage',
                label: 'Tablet Pozadinska slika',
                type: 'file',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },

            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'section[0].Title',
                label: 'Naslov',
                type: 'text',
                multilang: true,

                width: {
                    desktop: 6,
                    mobile: 12
                }

            },
            {
                name: 'section[0].TitleColor',
                label: 'Boja naslova',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[0].BlockColor',
                label: 'Pozadinska boja bloka',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[0].BlockWidthDesktop',
                label: 'Sirina bloka na desktopu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[0].BlockOffsetDesktop',
                label: 'Offset bloka na desktopu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },

            {
                name: 'section[0].BlockWidthMobile',
                label: 'Sirina bloka na telefonu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[0].BlockOffsetMobile',
                label: 'Offset bloka na telefonu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[0].Content',
                label: 'Tekst u bloku',
                type: 'html',
                multilang: true,

                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'spacer',
            },



            {
                type: 'label',
                beforeText: 'Sekcija #2',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'section[1].Image',
                label: 'Pozadinska slika',
                type: 'file',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[1].MobileImage',
                label: 'Mobilna pozadinska slika',
                type: 'file',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[1].TabletImage',
                label: 'Tablet pozadinska slika',
                type: 'file',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },

            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                name: 'section[1].BlockColor',
                label: 'Pozadinska boja bloka',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                name: 'section[1].BlockWidthDesktop',
                label: 'Sirina bloka na desktopu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[1].BlockOffsetDesktop',
                label: 'Offset bloka na desktopu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },

            {
                name: 'section[1].BlockWidthMobile',
                label: 'Sirina bloka na telefonu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[1].BlockOffsetMobile',
                label: 'Offset bloka na telefonu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[1].Title',
                label: 'Naslov',
                type: 'text',
                multilang: true,

                width: {
                    desktop: 6,
                    mobile: 12
                }

            },
            {
                name: 'section[1].TitleColor',
                label: 'Boja naslova',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },

            {
                name: 'section[1].Content',
                label: 'Tekst u bloku',
                type: 'html',
                multilang: true,

                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'spacer',
            },
            {
                type: 'label',
                beforeText: 'Sekcija #3',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                name: 'section[2].Image',
                label: 'Slika',
                type: 'file',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },

            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'section[2].BackgroundColor',
                label: 'Pozadinska boja sekcije',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },

            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                name: 'section[2].Title',
                label: 'Naslov',
                type: 'text',
                multilang: true,

                width: {
                    desktop: 6,
                    mobile: 12
                }

            },
            {
                name: 'section[2].TitleColor',
                label: 'Boja naslova',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[2].BlockColor',
                label: 'Pozadinska boja bloka',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[2].BlockWidthDesktop',
                label: 'Sirina bloka na desktopu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[2].BlockOffsetDesktop',
                label: 'Offset bloka na desktopu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },

            {
                name: 'section[2].BlockWidthMobile',
                label: 'Sirina bloka na telefonu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[2].BlockOffsetMobile',
                label: 'Offset bloka na telefonu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },








            {
                name: 'section[2].ImageWidthDesktop',
                label: 'Sirina slike na desktopu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[2].ImageOffsetDesktop',
                label: 'Offset slike na desktopu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },

            {
                name: 'section[2].ImageWidthMobile',
                label: 'Sirina slike na telefonu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[2].ImageOffsetMobile',
                label: 'Offset slike na telefonu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },

            {
                name: 'section[2].Content',
                label: 'Tekst u bloku',
                type: 'html',
                multilang: true,

                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'spacer',
            },
            {
                type: 'label',
                beforeText: 'Sekcija #4',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'section[3].Title',
                label: 'Naslov',
                type: 'text',
                multilang: true,

                width: {
                    desktop: 6,
                    mobile: 12
                }

            },
            {
                type: 'spacer',
            },
            {
                name: 'section[3].blocks[0].Image',
                label: 'Slika',
                type: 'file',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                name: 'section[3].blocks[0].Title',
                label: 'Naslov bloka',
                type: 'text',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[3].blocks[0].TitleColor',
                label: 'Boja naslova',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[3].blocks[0].Content',
                label: 'Tekst u bloku',
                type: 'html',
                multilang: true,

                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'spacer',
            },
            {
                name: 'section[3].blocks[1].Image',
                label: 'Slika',
                type: 'file',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                name: 'section[3].blocks[1].Title',
                label: 'Naslov bloka',
                type: 'text',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[3].blocks[1].TitleColor',
                label: 'Boja naslova',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[3].blocks[1].Content',
                label: 'Tekst u bloku',
                type: 'html',
                multilang: true,

                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'spacer',
            },
            {
                name: 'section[3].blocks[2].Image',
                label: 'Slika',
                type: 'file',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                name: 'section[3].blocks[2].Title',
                label: 'Naslov bloka',
                type: 'text',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[3].blocks[2].TitleColor',
                label: 'Boja naslova',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[3].blocks[2].Content',
                label: 'Tekst u bloku',
                type: 'html',
                multilang: true,

                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'spacer',
            },
            {
                name: 'section[3].blocks[3].Image',
                label: 'Slika',
                type: 'file',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                name: 'section[3].blocks[3].Title',
                label: 'Naslov bloka',
                type: 'text',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[3].blocks[3].TitleColor',
                label: 'Boja naslova',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[3].blocks[3].Content',
                label: 'Tekst u bloku',
                type: 'html',
                multilang: true,

                width: {
                    desktop: 12,
                    mobile: 12
                }

            },

            {
                type: 'spacer',
            },

            {
                type: 'label',
                beforeText: 'Sekcija #5',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'section[4].Image',
                label: 'Pozadinska slika',
                type: 'file',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[4].MobileImage',
                label: 'Mobilna pozadinska slika',
                type: 'file',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[4].TabletImage',
                label: 'Tablet pozadinska slika',
                type: 'file',
                multilang: true,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },

            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'section[4].Title',
                label: 'Naslov',
                type: 'text',
                multilang: true,

                width: {
                    desktop: 6,
                    mobile: 12
                }

            },
            {
                name: 'section[4].TitleColor',
                label: 'Boja naslova',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[4].BlockColor',
                label: 'Pozadinska boja bloka',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[4].BlockWidthDesktop',
                label: 'Sirina bloka na desktopu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[4].BlockOffsetDesktop',
                label: 'Offset bloka na desktopu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },

            {
                name: 'section[4].BlockWidthMobile',
                label: 'Sirina bloka na telefonu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[4].BlockOffsetMobile',
                label: 'Offset bloka na telefonu',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 3,
                    mobile: 12
                }

            },
            {
                name: 'section[4].Content',
                label: 'Tekst u bloku',
                type: 'html',
                multilang: true,

                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'spacer',
            },

        ],
        linkedFields: [
            {
                linkedCollection: 'categories',
                name: 'Breadcrumb',
                label: 'Category',
                type: 'category',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Alias,
                                value: item.Breadcrumb
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                linkedCollection: 'categories',
                name: 'Breadcrumb2',
                label: 'Additional category',
                type: 'category',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Alias,
                                value: item.Breadcrumb
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },


            {
                linkedCollection: 'units',
                name: 'OrderUnit',
                label: 'Jedinica mjere',
                type: 'select',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.name,
                                value: item.shortName
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                width: {
                    desktop: 3,
                    mobile: 12
                }



            },


            {
                linkedCollection: 'products',
                name: 'BundleProducts',
                label: 'Povezani proizvodi',
                type: 'select',
                multiselect: true,
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Name && item.Name.de,
                                value: item._id
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                width: {
                    desktop: 3,
                    mobile: 12
                }



            }

        ]
    },

    {
        title: 'Korisnik',
        subtitle: 'Dodaj/Izmjeni korisnika',
        link: '/users',
        collection: 'users',
        multilang: false,
        fields: [
            {
                name: 'User.Name',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'User.EMail',
                label: 'Email',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            /*{
                name: 'CustomerGroup',
                label: 'Korisnička grupa',
                type: 'select',
                values: [{ name: 'Fizičko lice', value: 'PhysicalPerson' }, { name: 'Pravno lice', value: 'LegalPerson' }],
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },

            {
                name: 'IP',
                label: 'IP Adresa',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'Coordinates',
                label: 'GPS Koordinate',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },*/ {
                name: 'User.PasswordsHistory[0]',
                label: 'Lozinka',
                type: 'password',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'UserRegion',
                label: 'Regija korisnika',
                type: 'select',
                values: [{ name: '1', value: '1' }, { name: '2', value: '2' }, { name: '3', value: '3' }, { name: '4', value: '4' }],
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'Alias',
                label: 'Redni broj korisnika',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'TimeToPay',
                label: 'Odloženo plaćanje',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },

            /*{
                name: 'EnableGPSBlock',
                label: 'Omogući GPS blokiranje',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },*/

            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            /*{
                name: 'Coordinates',
                label: 'Mapa',
                type: 'map',
                width: {
                    desktop: 6,
                    mobile: 12
                }

            },*/
            {
                type: 'spacer'
            },
            {
                name: 'EmailVerified',
                label: 'Verifikovan email',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            /*{
                name: 'IsSalesManager',
                label: 'Komercijalista',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },*/
            {
                name: 'SubscribeToNewsletter',
                label: 'Prijavljen na newsletter',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },

            {
                type: 'title',
                text: 'Adresa za obračun'
            },

            {
                name: 'BillingAddress.FirstName',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.LastName',
                label: 'Prezime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Street',
                label: 'Ulica i broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.City',
                label: 'Grad',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Zipcode',
                label: 'Poštanski broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Company',
                label: 'Firma',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.EMail',
                label: 'EMail',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Phone',
                label: 'Telefon',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.JIB',
                label: 'JIB',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.PDV',
                label: 'PDV',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.MB',
                label: 'MB',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.TaxPayer',
                label: 'Obavezik PDV-a',
                type: 'select',
                values: [
                    {
                        name: 'NE',
                        value: '0'
                    }, {
                        name: 'DA',
                        value: '1'
                    }
                ],
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },



            {
                type: 'spacer'
            },




            {
                type: 'title',
                text: 'Adresa za isporuku'
            },

            {
                name: 'ShippingAddress.FirstName',
                label: 'Ime ili naziv firme',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.LastName',
                label: 'Prezime ili poslovna jedinica',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Street',
                label: 'Ulica i broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            }, {
                name: 'ShippingAddress.City',
                label: 'Grad',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Zipcode',
                label: 'Poštanski broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },

            {
                name: 'ShippingAddress.EMail',
                label: 'EMail',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Phone',
                label: 'Telefon',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },


        ],
        linkedFields: []

    }, {
        title: 'Kategorija',
        subtitle: 'Dodaj/Izmjeni kategoriju',
        link: '/categories',
        collection: 'categories',
        multilang: true,

        fields: [
            {
                name: 'Image',
                label: 'Slika',
                type: 'file',
                multilang: true,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'MobileImage',
                label: 'Mobilna Slika',
                type: 'file',
                multilang: true,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'TabletImage',
                label: 'Tablet Slika',
                type: 'file',
                multilang: true,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },

            {
                name: 'Alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                generateAlias: true,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Name',
                label: 'Naziv',
                type: 'text',
                multilang: true,

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Position',
                label: 'Pozicija',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'IsVisible',
                label: 'Vidljiva',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

        ],
        linkedFields: [
            {
                linkedCollection: 'categories',
                name: 'ParentCategory',
                label: 'Kategorija',
                type: 'select',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Alias,
                                value: item._id
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                width: {
                    desktop: 12,
                    mobile: 12
                }
            }

        ]

    },
    {
        title: 'Atribut',
        subtitle: 'Dodaj/Izmjeni atribut',
        link: '/attributes',
        collection: 'attributes',
        multilang: false,
        fields: [
            {
                name: 'Alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                generateAlias: true,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Name',
                label: 'Naziv',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Values',
                label: 'Vrijednosti',
                type: 'tags',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

        ],
        linkedFields: [


        ]

    },
    {
        title: 'Novosti',
        subtitle: 'Dodaj/Izmjeni novost',
        link: '/news',
        collection: 'news',
        multilang: true,
        fields: [
            {
                name: 'Image',
                label: 'Slika',
                type: 'file',
                multilang: true,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                generateAlias: true,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Title',
                label: 'Naslov',
                type: 'text',
                multilang: true,

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShortDescription',
                label: 'Kratak opis',
                type: 'text-multiline',
                multilang: true,

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Content',
                label: 'Sadrzaj',
                type: 'html',
                multilang: true,
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'IsVisible',
                label: 'Aktivna',
                type: 'checkbox',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'spacer'
            },

        ],
        linkedFields: [


        ]

    },
    {
        title: 'Galerija',
        subtitle: 'Izmjeni galeriju',
        link: '/gallery',
        collection: 'gallery',

        fields: [
            {
                name: 'Images',
                label: 'Slike',
                type: 'file-multiple',
                multilang: false,
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },

        ],
        linkedFields: [


        ]

    },

    {
        title: 'Product type',
        subtitle: 'Change category',
        link: '/product-types',
        collection: 'productTypes',

        fields: [
            {
                name: 'alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'name',
                label: 'Name',
                type: 'text',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'position',
                label: 'Position',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: '_attribute',
                label: 'Select attribute to edit',
                type: 'select',
                values: [],
                additionalAction: (scope, value) => {
                    let fields = scope.state.fields;
                    let newFields = [];

                    let found = false;
                    for (let i = 0; i < fields.length; i++) {

                        if ((fields[i].type === 'title' && !found) || i === fields.length - 1) {

                            if (i === fields.length - 1) {
                                newFields.push(fields[i]);
                            }

                            newFields.push({
                                type: 'title',
                                text: value
                            }
                            );


                            newFields.push({
                                name: 'attributes.' + value + ".alias",
                                label: 'Alias',
                                type: 'text',
                                multilang: false,
                                width: {
                                    desktop: 6,
                                    mobile: 12
                                }
                            }
                            );

                            newFields.push({
                                name: 'attributes.' + value + ".name",
                                label: 'Name',
                                type: 'text',

                                width: {
                                    desktop: 6,
                                    mobile: 12
                                }
                            }
                            );

                            for (let j = 0; j < scope.state.initialValues.attributes[value].values.length; j++) {

                                newFields.push({
                                    name: 'attributes.' + value + ".values[" + j + "].value",
                                    label: 'Item[' + j + "] Value",
                                    type: 'text',
                                    multilang: false,
                                    width: {
                                        desktop: 3,
                                        mobile: 12
                                    }
                                }

                                );

                                newFields.push({
                                    name: 'attributes.' + value + ".values[" + j + "].name",
                                    label: 'Item[' + j + "] Name",
                                    type: 'text',

                                    width: {
                                        desktop: 3,
                                        mobile: 12
                                    }
                                }

                                );

                                newFields.push({
                                    type: 'offset',
                                    width: {
                                        desktop: 12,
                                        mobile: 12
                                    }
                                })

                            }


                            newFields.push({
                                type: 'spacer',
                            }
                            );


                            found = true;
                            if (i !== fields.length - 1) {

                                newFields.push(fields[i]);
                            }

                        } else {
                            newFields.push(fields[i]);
                        }

                    }


                    scope.setState({ fields: newFields })

                },
                renderItems: (data) => {
                    return Object.values(data.attributes).map((item) => {
                        return (
                            {
                                name: item.alias,
                                value: item.alias
                            }
                        )
                    })
                },
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            }

            , {
                type: 'button',
                text: 'New attribute',
                width: {
                    desktop: 12,
                    mobile: 12
                },

                onClick: (scope) => {
                    scope.props.handlePrompt({
                        text: 'Enter attribute alias',
                        callback: (value) => {
                            let fields = scope.state.fields;
                            let newFields = [];

                            let found = false;
                            for (let i = 0; i < fields.length; i++) {

                                if ((fields[i].type === 'title' && !found) || i === fields.length - 1) {

                                    if (i === fields.length - 1) {
                                        newFields.push(fields[i]);
                                    }

                                    newFields.push({
                                        type: 'title',
                                        text: value
                                    }
                                    );


                                    newFields.push({
                                        name: 'attributes.' + value + ".alias",
                                        label: 'Alias',
                                        type: 'text',
                                        multilang: false,
                                        width: {
                                            desktop: 6,
                                            mobile: 12
                                        }
                                    }
                                    );

                                    newFields.push({
                                        name: 'attributes.' + value + ".name",
                                        label: 'Name',
                                        type: 'text',

                                        width: {
                                            desktop: 6,
                                            mobile: 12
                                        }
                                    }
                                    );


                                    newFields.push({
                                        type: 'button',
                                        text: 'Add value',
                                        width: {
                                            desktop: 12,
                                            mobile: 12
                                        },

                                        onClick: (scope) => {
                                            let f = scope.state.fields;
                                            let nF = [];
                                            for (let j = 0; j < f.length; j++) {
                                                if (f[j].name === 'attributes.' + value + ".name") {


                                                    let k = 0;
                                                    for (let l = 0; l < f.length; l++) {
                                                        if (f[l].name && f[l].name.indexOf('attributes.' + value + ".values") !== -1) {
                                                            k++;
                                                        }
                                                    }

                                                    k /= 2;
                                                    console.log(k);

                                                    nF.push(f[j]);

                                                    nF.push({
                                                        name: 'attributes.' + value + ".values[" + (k) + "].value",
                                                        label: 'Item[' + (k) + "] Value",
                                                        type: 'text',
                                                        multilang: false,
                                                        width: {
                                                            desktop: 3,
                                                            mobile: 12
                                                        }
                                                    }

                                                    );

                                                    nF.push({
                                                        name: 'attributes.' + value + ".values[" + (k) + "].name",
                                                        label: 'Item[' + (k) + "] Name",
                                                        type: 'text',

                                                        width: {
                                                            desktop: 3,
                                                            mobile: 12
                                                        }
                                                    });

                                                    nF.push({
                                                        type: 'offset',
                                                        width: {
                                                            desktop: 12,
                                                            mobile: 12
                                                        }
                                                    })

                                                } else {
                                                    nF.push(f[j]);
                                                }
                                            }

                                            scope.setState({
                                                fields: nF
                                            })

                                        },
                                    })


                                    newFields.push({
                                        type: 'spacer',
                                    }
                                    );


                                    found = true;
                                    if (i !== fields.length - 1) {

                                        newFields.push(fields[i]);
                                    }

                                } else {
                                    newFields.push(fields[i]);
                                }

                            }


                            scope.setState({ fields: newFields })
                        }
                    })
                }

            },
            {
                type: 'spacer'
            }
        ],
        linkedFields: [

        ]
    },
    {
        title: 'Dostava',
        subtitle: 'Dodaj/Izmjeni dostavu',
        link: '/delivery-methods',
        collection: 'deliveryMethods',
        multilang: false,
        fields: ['Österreich', 'Litauen', 'Belgien', 'Luxemburg', 'Bulgarien', 'Ungarn', 'Zypern', 'Malta', 'Tschechische Republik', 'Deutschland', 'Dänemark', 'Niederlande', 'Estland', 'Polen', 'Finnland', 'Portugal', 'Frankreich', 'Rumänien', 'Griechenland', 'Slowakei', 'Kroatien', 'Slowenien', 'Irland', 'Spanien', 'Italien', 'Schweden', 'Lettland'].map((item) => {
            return {
                name: item,
                label: item,
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            }


        }),
        linkedFields: []
    },

    {
        title: 'Slide',
        subtitle: 'Change slide',
        link: '/slides',
        collection: 'slides',
        multilang: true,
        fields: [

            {
                name: 'Image',
                label: 'Slika',
                type: 'file',
                multilang: true,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'MobileImage',
                label: 'Mobilna Slika',
                type: 'file',
                multilang: true,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'TabletImage',
                label: 'Tablet Slika',
                type: 'file',
                multilang: true,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'Link',
                label: 'Link',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                name: 'Position',
                label: 'Pozicija',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },



        ],
        linkedFields: []

    },
    {
        title: 'Banner',
        subtitle: 'Dodaj/Izmjeni baner',
        link: '/banners',
        collection: 'banners',
        multilang: true,
        fields: [

            {
                name: 'Image',
                label: 'Image',
                type: 'file',
                multilang: true,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },
            {
                name: 'Link',
                label: 'Link',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },

    {
        title: 'Settings',
        subtitle: 'Change settings',
        link: '/settings',
        collection: 'settings',
        multilang: false,
        fields: [
            {
                name: 'footerText',
                label: 'Footer Text',
                type: 'text-multiline',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'address',
                label: 'Address',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'phone',
                label: 'Phone',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'email',
                label: 'Email',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },

    {
        title: 'Pop Up',
        subtitle: 'Change Pop Up',
        link: '/popup',
        collection: 'popup',
        multilang: false,
        fields: [
            {
                name: 'Title',
                label: 'Title',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'Subtitle',
                label: 'Subtitle',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'Text',
                label: 'Text',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'Image',
                label: 'Image',
                type: 'file',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'Background',
                label: 'Background',
                type: 'file',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },
    {
        title: 'Stranice',
        subtitle: 'Izmjena stranice',
        link: '/pages',
        collection: 'pages',
        multilang: true,
        fields: [

            {
                name: 'title',
                label: 'Naslov',
                type: 'text',
                multilang: true,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'alias',
                label: 'Alias',
                type: 'text',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },

            {
                name: 'content',
                label: 'Tekst',
                type: 'html',
                multilang: true,
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },
    {
        title: 'Jedinice mjere',
        subtitle: 'Izmjena',
        link: '/units',
        collection: 'units',
        multilang: false,
        fields: [

            {
                name: 'name',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'shortName',
                label: 'Skracenica',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },
]


let listPages = [
    {
        title: 'Stranice',
        subtitle: 'Lista stranica',
        link: '/pages',
        collection: 'pages',
        searchFields: ['Alias', 'Title'],
        itemFields: [
            {
                name: "alias",
                label: "Alias",
                type: "text",
            }, {
                name: "title.de",
                label: "Naslov",
                type: "text",
            },
        ],

    },
    {
        title: 'Jedinice mjere',
        subtitle: 'Lista',
        link: '/units',
        collection: 'units',
        searchFields: ['Alias', 'Title'],
        itemFields: [
            {
                name: "name",
                label: "Ime",
                type: "text",
            },
        ],

    },

    {
        title: 'Admins',
        subtitle: 'Lista admina',
        link: '/admins',
        collection: 'admins',
        searchFields: ['username', 'userRole'],
        itemFields: [
            {
                name: "username",
                label: "Username",
                type: "text",
            },
            {
                name: 'userRole',
                label: "Tip",

                type: "text"
            },
        ],
    },
    {
        title: 'Atributi',
        subtitle: 'Lista atributa',
        link: '/attributes',
        collection: 'attributes',
        searchFields: ['Alias', 'Name'],
        itemFields: [
            {
                name: "Alias",
                label: "Alias",
                type: "text",
            },
            {
                name: 'Name',
                label: "Naziv",

                type: "text"
            },
        ],
    }, {
        title: 'Novosti',
        subtitle: 'Lista novosti',
        link: '/news',
        haveImage: true,
        collection: 'news',
        searchFields: ['Alias', 'Title'],
        itemFields: [
            {
                name: 'Image',
                type: "image",
                disableSort: true
            }, {
                name: "Alias",
                label: "Alias",
                type: "text",
            },
            {
                name: 'Title.de',
                label: "Naslov",

                type: "text"
            },
        ],
        sortOptions: [
            {
                name: 'Po naslovu',
                field: 'Title',
                sort: 1
            },
        ]
    }, {
        title: 'Proizvodi',
        subtitle: 'Lista proizvoda',
        link: '/products',
        haveImage: true,
        //multilang: true,
        collection: 'products',
        searchFields: ['Alias', 'Name', 'Name.de'],
        itemFields: [
            {
                name: 'Images.de[0]',
                type: "image",
                disableSort: true
            }, {
                name: "Alias",
                label: "Šifra proizvoda",
                type: "text",
            },
            {
                name: 'Name.de',
                label: "Naziv",
                type: "text",
                //multilang: true,
            },
            {
                name: "StockLevel",
                label: "Stanje",

                type: "number",
                allowEdit: true

            },
            {
                name: "discount",
                label: "Stara cijena[%]",

                type: "number",
                allowEdit: true

            }

        ],
        sortOptions: [
            {
                name: 'Po imenu',
                field: 'Name.de',
                sort: 1
            },
            {
                name: 'MPC - ascending',
                field: 'price',
                sort: 1
            },
            {
                name: 'Price - descending',
                field: 'price',
                sort: -1
            },
            {
                name: 'Stock - ascending',
                field: 'StockLevel',
                sort: 1
            },
            {
                name: 'Stock - descending',
                field: 'StockLevel',
                sort: -1
            }
        ]
    },
    {
        title: 'Korisnici',
        subtitle: 'Lista korisnika',
        link: '/users',
        haveImage: false,
        collection: 'users',
        searchFields: ['Alias', 'User.Name', 'User.EMail', 'Alias'],
        itemFields: [
            /*{
                name: '_id',
                type: "text"
            }*/,
            {
                name: 'Alias',
                label: 'Redni br.',
                type: "text"
            },
            {
                name: 'UserRegion',
                label: 'Regija',
                type: "text"
            },

            {
                name: 'User.Name',
                label: 'Name',
                type: "text"
            },
            {
                name: 'User.EMail',
                label: 'EMail',
                type: "text"
            },
            {
                name: 'BillingAddress.City',
                label: 'Grad',
                type: "text"
            },

            /*{
                name: 'CustomerGroup',
                label: 'Korisnička grupa',
                type: "text"
            },*/

        ],
    }, {
        title: 'Kategorije',
        subtitle: 'Lista kategorija',
        link: '/categories',
        collection: 'categories',
        searchFields: ['Alias', 'Name'],
        itemFields: [
            {
                name: 'Alias',
                type: "text",
                label: "Alias"
            },
            {
                name: 'Breadcrumb',
                type: "text",
                label: "Breadcrumb"

            },
        ],

    }, {
        title: 'Dostave',
        subtitle: 'Lista dostava',
        link: '/delivery-methods',
        haveImage: false,
        collection: 'deliveryMethods',
        searchFields: ['alias', 'name'],
        itemFields: [
            {
                name: 'alias',
                type: "text",
                label: "Alias"

            },
            {
                name: 'name.de',
                type: "text",
                label: "Name"

            },
            {
                name: 'price',
                type: "price",
                label: "Price"

            },
        ],
    },
    {
        title: 'Slider na početnoj',
        subtitle: 'Lista slajdova',
        link: '/slides',
        haveImage: true,
        collection: 'slides',
        searchFields: [],
        itemFields: [
            {
                name: 'Image.de',
                type: "image"
            },
        ],
    },
    {
        title: 'Baneri',
        subtitle: 'Lista banera',
        link: '/banners',
        haveImage: true,
        collection: 'banners',
        searchFields: ['Title', 'Subtitle'],
        itemFields: [
            {
                name: 'Image.de',
                type: "image"
            },
        ],
    },
    {
        title: 'Newsletters',
        subtitle: 'List of newsletters',
        link: '/newsletters',
        haveImage: true,
        collection: 'newsletters',
        searchFields: ['Title', 'Text'],
        itemFields: [
            {
                name: 'Title',
                type: "text"
            },

        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    },
    {
        title: 'Popup',
        subtitle: 'List of popup',
        link: '/popup',
        haveImage: true,
        collection: 'popup',
        searchFields: ['Title', 'Subtitle'],
        itemFields: [
            {
                name: 'Image',
                type: "image"
            }, {
                name: 'Title',
                type: "text"
            },

        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    }, {
        title: 'Kontakti',
        subtitle: 'Lista kontakata',
        link: '/contacts',
        collection: 'contacts',
        disableDelete: true,
        disableEdit: true,
        searchFields: ['EMail', 'Name'],
        itemFields: [
            {
                name: 'Name',
                label: 'Ime',
                type: "text"
            },
            {
                name: 'EMail',
                label: 'EMail',

                type: "text"
            }, {
                name: 'Subject',
                label: 'Naslov',

                type: "text"
            }, {
                name: 'Message',
                label: 'Poruka',

                type: "text"
            },

        ],
    },
    {
        title: 'Narudzbe',
        subtitle: 'Lista narudzbi',
        link: '/orders',
        collection: 'orders',
        disableDelete: true,
        searchFields: ['PayPalTransaction.transactions.invoice_number', 'BillingAddress.FirstName', 'BillingAddress.LastName', 'BillingAddress.Company', 'ShippingAddress.Name', 'ShippingAddress.Street', 'ShippingAddress.City', 'ShippingAddress.Phone'],
        itemFields: [
            {
                name: 'orderNumber',
                label: 'Br. narudžbe',
                type: "text"
            },
            {
                name: 'stripeId',
                label: 'StripeID',
                type: "text"
            },
            {
                name: 'stripeStatus',
                label: 'Stripe Status',
                type: "text"
            },

            {
                name: 'paypalId',
                label: 'PayPalId',
                type: "text"
            },


            {
                name: 'orderTime',
                label: 'Datum',

                type: "datetime",
                format: "DD.MM.YYYY"
            },

            /*{
                name: 'ShippingAddress.FirstName',
                label: 'Firma ili ime',
                type: "text"
            },
            {
                name: 'ShippingAddress.LastName',
                label: 'PJ / Prezime',

                type: "text"
            },*/
            {
                name: 'ShippingAddress.Name',
                label: 'Ime',
                type: "text"
            },
            {
                name: 'ShippingAddress.City',
                label: 'Grad',

                type: "text"
            },
            /*{
                name: 'DeliveryMethod.name.de',
                label: 'Dostava',

                type: "text"
            },
            {
                name: 'BillingMethod',
                label: 'Način plaćanja',

                type: "text"
            },*/

            {
                name: 'total',
                label: 'Ukupno',
                type: "price"
            },

            /*{
                name: 'Payed',
                label: 'Plaćeno',
                type: "price",
                allowEdit: true
            },

            {
                name: 'ToPay',
                label: 'Dug',
                type: "price"
            },*/
            {
                name: 'Status',
                label: 'Status',
                type: "text"
            },
            /*{
                name: 'timeToPay',
                label: 'Valua plaćanja',

                type: "datetime",
                format: "DD.MM.YYYY"
            },*/

        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    }, {
        title: 'Brendovi',
        subtitle: 'Lista brendova',
        link: '/brands',
        collection: 'brands',
        searchFields: [],
        haveImage: true,
        itemFields: [

            {
                name: 'Image',
                label: 'Slika',
                type: "image",
                disableSort: true

            },
            {
                name: 'Position',
                label: 'Pozicija',
                allowEdit: true,
                type: "number"
            },

        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    },
    {
        title: 'Firme',
        subtitle: 'Lista firmi',
        link: '/companies',
        collection: 'companies',
        searchFields: [],
        haveImage: true,
        itemFields: [

            {
                name: 'Image',
                label: 'Slika',
                type: "image",
                disableSort: true

            },
            {
                name: 'Link',
                label: 'Link',
                type: "text"
            },
            {
                name: 'Position',
                label: 'Pozicija',
                allowEdit: true,
                type: "number"
            },

        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    },

];


class Routes extends Component {

    componentDidMount() {
        /*const unlisten = history.listen((location, action) => {
            this.props.handleMenu(null);
        });
        */
    }

    render() {
        return (
            <Router >
                <div>

                    <GoogleMapScript API_KEY="AIzaSyAAqbIo7N0_Rpwtay3-CWzo5gkfpgWZ4to" />

                    <Switch className="react-switch">

                        {this.props.uData && this.props.uData.userRole == 'super' ?

                            <Route
                                path="/"
                                exact
                                render={(...renderProps) => (
                                    <HomePage {...renderProps} {...this.props} />
                                )}
                            />

                            :
                            !this.props.uData ?
                                <Route
                                    path="/"
                                    exact
                                    render={(...renderProps) => (
                                        <LoginPage {...renderProps} {...this.props} />
                                    )}
                                />

                                : null


                        }

                        <Route
                            path="/login"
                            exact
                            render={(...renderProps) => (
                                <LoginPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/order/:id"
                            exact
                            render={(...renderProps) => (
                                <Order {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/statistics"
                            exact
                            render={(...renderProps) => (
                                <Statistics {...renderProps} {...this.props} />
                            )}
                        />


                        {
                            this.props.uData ?
                                <Route
                                    path="/skin-consult"
                                    exact
                                    render={(...renderProps) => (
                                        <SkinConsultBuilder {...renderProps} {...this.props} />
                                    )}
                                />

                                :
                                null
                        }

                        {
                            listPages.map((page, idx) => {
                                //console.log(page.link, (this.props.uData && (this.props.uData.userRole == 'super' || page.link == '/orders' || (this.props.uData.userRole == 'special' && page.link == '/products') || (this.props.uData.userRole == 'simple' && (page.link == '/users' || page.link == '/priceList/:uid')))))
                                if (this.props.uData && (this.props.uData.userRole == 'super' || page.link == '/orders' || page.link == '/user-orders/:uid' || (this.props.uData.userRole == 'special' && (page.link == '/products' || page.link == '/users' || page.link == '/priceList/:uid')) || (this.props.uData.userRole == 'simple' && (page.link == '/users' || page.link == '/priceList/:uid')))) {

                                    return (

                                        <Route
                                            path={page.link}
                                            exact
                                            render={(...renderProps) => (
                                                <ListPage {...page} {...renderProps} {...this.props} />
                                            )}
                                        />



                                    )
                                }

                            })

                        }

                        {
                            listPages.map((page, idx) => {
                                //console.log(page.link, (this.props.uData && (this.props.uData.userRole == 'super' || page.link == '/orders' || (this.props.uData.userRole == 'special' && page.link == '/products') || (this.props.uData.userRole == 'simple' && (page.link == '/users' || page.link == '/priceList/:uid')))))
                                if (this.props.uData && ((this.props.uData.userRole == 'special' && page.link == '/products') || (this.props.uData.userRole == 'simple' && page.link == '/users'))) {
                                    return (
                                        <Route
                                            path={'/'}
                                            exact
                                            render={(...renderProps) => (
                                                <ListPage {...page} {...renderProps} {...this.props} />
                                            )}
                                        />
                                    )
                                }
                            })
                        }

                        {
                            formPages.map((page, idx) => {
                                if (this.props.uData && (this.props.uData.userRole == 'super' || page.link == '/orders' || (this.props.uData.userRole == 'special' && (page.link == '/products' || page.link == '/users' || page.link == '/priceList/:uid')) || (this.props.uData.userRole == 'simple' && (page.link == '/users' || page.link == '/priceList/:uid'))))
                                    return (

                                        <Route
                                            path={`${page.link}/:id`}
                                            exact
                                            render={(...renderProps) => (
                                                <FormPage {...page} {...renderProps} {...this.props} />
                                            )}
                                        />



                                    )

                            })
                        }
                        {
                            formPages.map((page, idx) => {
                                if (this.props.uData && (this.props.uData.userRole == 'super' || this.props.uData.userRole == 'special') && (page.link === '/gratis-products' || page.link === '/free-delivery'))
                                    return (

                                        <Route
                                            path={`${page.link}`}
                                            exact
                                            render={(...renderProps) => (
                                                <FormPage {...page} {...renderProps} {...this.props} />
                                            )}
                                        />



                                    )

                            })
                        }




                    </Switch>
                </div>
            </Router >
        );
    }
}

export default Routes;